// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-s-3-fake-index-js": () => import("./../../../node_modules/gatsby-plugin-s3/fake-index.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-s-3-fake-index-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-alle-projekte-tsx": () => import("./../../../src/templates/alle-projekte.tsx" /* webpackChunkName: "component---src-templates-alle-projekte-tsx" */),
  "component---src-templates-anwendungen-projekte-tsx": () => import("./../../../src/templates/anwendungenProjekte.tsx" /* webpackChunkName: "component---src-templates-anwendungen-projekte-tsx" */),
  "component---src-templates-anwendungen-tsx": () => import("./../../../src/templates/anwendungen.tsx" /* webpackChunkName: "component---src-templates-anwendungen-tsx" */),
  "component---src-templates-dienstleistungen-tsx": () => import("./../../../src/templates/dienstleistungen.tsx" /* webpackChunkName: "component---src-templates-dienstleistungen-tsx" */),
  "component---src-templates-hersteller-downloads-tsx": () => import("./../../../src/templates/hersteller-downloads.tsx" /* webpackChunkName: "component---src-templates-hersteller-downloads-tsx" */),
  "component---src-templates-hersteller-tsx": () => import("./../../../src/templates/hersteller.tsx" /* webpackChunkName: "component---src-templates-hersteller-tsx" */),
  "component---src-templates-hersteller-uebersicht-tsx": () => import("./../../../src/templates/hersteller-uebersicht.tsx" /* webpackChunkName: "component---src-templates-hersteller-uebersicht-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-projekte-tsx": () => import("./../../../src/templates/projekte.tsx" /* webpackChunkName: "component---src-templates-projekte-tsx" */),
  "component---src-templates-swissblock-tsx": () => import("./../../../src/templates/swissblock.tsx" /* webpackChunkName: "component---src-templates-swissblock-tsx" */),
  "component---src-templates-systemsteine-tsx": () => import("./../../../src/templates/systemsteine.tsx" /* webpackChunkName: "component---src-templates-systemsteine-tsx" */),
  "component---src-templates-text-seite-tsx": () => import("./../../../src/templates/text-seite.tsx" /* webpackChunkName: "component---src-templates-text-seite-tsx" */)
}

